const makeTypes = m => (a, isAsync) => {
  if (isAsync) {
    return {
      START: `${m}/${a}-start`,
      SUCCESS: `${m}/${a}-success`,
      LOGOUT: `${m}/${a}-logout`,
      ERROR: `${m}/${a}-error`
    };
  }
  return `${m}/${a}`;
};

const makeActions = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};

export { makeTypes, makeActions };
