
import axios from 'axios';
import auth from '../auth';
import { makeTypes, makeActions as mac } from '../utils/reducers';
import err from '../utils/errors';

const t = makeTypes('account');

//TYPES
const SUBMIT = t('submit', true); // this create a login/submit constant type

//ACTIONS
export const submitStart = mac(SUBMIT.START); //NEVER USER 'type' AS PARAM
export const submitSuccess = mac(SUBMIT.SUCCESS, 'payload'); //NEVER USER 'type' AS PARAM
export const submitError = mac(SUBMIT.ERROR, 'error'); //NEVER USER 'type' AS PARAM

//STATE INITIAL
const initState = {
  fetching: false,
  error: null, // msg
  hasAccountData: false,
  accountData: null
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SUBMIT.START:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case SUBMIT.SUCCESS:
      return {
        ...state,
        error: null,
        hasAccountData: true,
        fetching: false,
        accountData: action.payload,
      };
    case SUBMIT.ERROR:
      return {
        ...state,
        error: action.error,
        hasAccountData: false,
        fetching: false,
      };
  default:
    return state;
  }
}

//TUNKS
export const getAccountData = () =>
  async (dispatch, getState) => {
    dispatch(submitStart());
    try {
      if (auth.isUserAuthenticated() === false) {
        dispatch(submitError(err.sessionExpired));
      }
      const token = auth.getToken();
      let path = `https://cuby.cloud/api/v2/devices?token=${token}`;
      let res = await axios.get(path);
    if (res.data != null) {
      dispatch(submitSuccess(res.data));
    } else {
      dispatch(submitError(err.internal));
    }
    } catch (e) {
      console.log(JSON.stringify(e));
      if (e.message != null && e.message.includes('401')) {
        dispatch(submitError(err.sessionExpired));
      } else {
        dispatch(submitError(err.internal));
      }
    }

  };
