export const logoNegative = ['690 134', `
  <title>Cuby Smart +</title>
<g transform="translate(200.000000,130.000000) scale(0.03000,-0.030000)"
fill="#ffffff" stroke="none">
<path d="M2940 3687 l0 -155 83 -7 c321 -27 583 -134 819 -332 184 -156 334
-378 412 -614 36 -109 66 -255 66 -325 0 -23 3 -56 6 -73 l7 -31 154 0 155 0
-6 88 c-35 438 -200 807 -496 1102 -296 297 -668 463 -1107 496 l-93 7 0 -156z"/>
<path d="M2920 3096 l0 -153 74 -7 c105 -9 206 -40 311 -94 74 -38 107 -63
190 -146 81 -82 109 -118 147 -191 53 -101 84 -202 94 -305 l7 -70 153 0 154
0 0 34 c0 62 -21 187 -46 274 -103 354 -380 641 -729 755 -92 30 -236 57 -306
57 l-49 0 0 -154z"/>
<path d="M1478 2521 c-130 -42 -218 -135 -253 -266 -23 -88 -23 -1431 0 -1520
31 -122 133 -228 255 -266 64 -19 89 -20 800 -17 l735 3 65 31 c102 49 169
125 204 230 15 46 16 120 14 800 l-3 749 -28 57 c-38 77 -107 144 -187 182
l-65 31 -740 2 c-692 2 -744 1 -797 -16z m1400 -287 c58 -29 96 -69 126 -134
21 -44 21 -58 21 -605 l0 -560 -28 -57 c-16 -32 -45 -71 -65 -88 -73 -60 -71
-60 -681 -60 -537 0 -557 1 -601 21 -55 25 -105 71 -133 124 -22 40 -22 44
-22 620 0 576 0 580 22 620 28 53 78 99 133 124 44 20 63 21 611 21 l565 0 52
-26z"/>
<path d="M2175 1715 c-88 -34 -148 -123 -148 -220 0 -187 205 -296 360 -192
145 97 133 320 -21 399 -52 26 -139 32 -191 13z"/>
<path d="M6500 2411 l-115 -37 -3 -902 -2 -903 57 -25 c154 -68 381 -104 544
-85 305 34 515 196 604 466 37 108 45 287 21 399 -24 105 -83 219 -156 299
-173 189 -423 249 -673 162 -41 -14 -76 -25 -80 -25 -3 0 -7 147 -9 326 l-3
326 -24 19 c-29 24 -23 24 -161 -20z m576 -886 c225 -67 302 -389 147 -622
-42 -63 -91 -104 -170 -141 -52 -24 -69 -27 -168 -27 -60 0 -128 6 -151 13
l-41 12 -8 327 -7 327 29 24 c50 42 101 74 140 88 51 18 168 18 229 -1z"/>
<path d="M7847 1806 c-54 -29 -103 -58 -108 -63 -7 -7 70 -161 241 -484 l252
-474 -56 -110 c-127 -254 -253 -370 -439 -406 -46 -9 -61 -17 -71 -38 -13 -24
-10 -33 36 -126 28 -55 52 -102 54 -104 8 -9 149 21 198 41 139 58 310 205
400 346 51 80 655 1302 659 1333 2 18 -16 32 -103 80 -77 43 -111 57 -128 52
-20 -5 -57 -72 -203 -367 -99 -198 -183 -364 -188 -369 -4 -4 -95 158 -200
360 -106 202 -198 371 -204 375 -22 14 -42 8 -140 -46z"/>
<path d="M4070 1804 c-182 -35 -361 -165 -442 -319 -148 -283 -102 -632 111
-846 129 -129 270 -182 486 -181 139 0 233 17 389 69 l108 37 -5 36 c-5 43
-62 182 -86 211 l-17 21 -90 -30 c-377 -127 -627 -30 -674 261 -25 157 38 323
153 401 127 86 315 88 508 7 40 -17 76 -27 80 -23 4 4 30 53 59 110 66 130 65
136 -51 180 -144 55 -193 64 -354 67 -82 2 -161 1 -175 -1z"/>
<path d="M4916 1768 c-14 -19 -16 -68 -16 -338 0 -173 5 -380 10 -459 8 -133
12 -150 41 -210 66 -135 207 -245 370 -287 102 -27 270 -22 394 11 55 15 109
29 120 32 13 3 48 -14 99 -47 71 -46 81 -50 106 -40 l27 11 7 656 c6 607 5
657 -10 674 -15 17 -33 19 -144 19 -110 0 -129 -2 -143 -18 -15 -17 -17 -66
-17 -499 l0 -481 -52 -23 c-46 -21 -68 -24 -173 -24 -135 0 -178 13 -250 76
-77 68 -78 72 -83 534 -3 252 -8 415 -14 423 -7 8 -47 12 -134 12 -116 0 -124
-1 -138 -22z"/>
</g>
`]
