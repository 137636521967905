import axios from 'axios';
const BASE_URL = `https://cuby.cloud/api/v2/`;

const post = async (url, token, body) => {
  const URL = `${BASE_URL}${url}`;

  const headers = {
    'Authorization': token
  };
  try {
    console.log(`[ideaAPI] POST:: ${URL}, body: `, body);
    const res = await axios.post(URL, body, {headers});
    return res;
  }
  catch(err) {
    console.error(`[cubyAPI] POST:: `, err);
    const { response = {} } = err;
    const { data = {} } = response;
    const { status = "error" } = data;
    throw status;
  }
};

export { post };
