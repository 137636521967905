const CommandType = {
  IS_ON: {
    label: "Power",
    value: "a",
    tagId: "power"
  },
  MODE: {
    label: "Mode",
    value: "b",
    tagId: "mode",
    options: [
      {key: "cool", value: "Cool"},
      {key: "heat", value: "Heat"},
      {key: "fan", value: "Fan"},
      {key: "dry", value: "Dry"},
      {key: "auto", value: "Auto"},
    ],
  },
  FAN: {
    label: "Fan",
    value: "c",
    tagId: "fan",
    options: [
      {key: "low", value: "Low"},
      {key: "mid", value: "Mid"},
      {key: "high", value: "High"},
      {key: "auto", value: "Auto"},
    ],
  },
  TEMP: {
    label: "Temperature",
    value: "f",
    tagId: "temperature"
  },
  VERT_VANE: {
    label: "Vertical Vane",
    value: "o",
    tagId: "verticalVane",
    options: [
      { key: "top", value: "Top"},
      { key: "topcenter", value: "Top Center"},
      { key: "center", value: "Center"},
      { key: "bot", value: "Bottom"},
      { key: "botcenter", value: "Bottom Center"},
      { key: "auto", value: "Auto"},
      { key: "off",  value: "OFF"},
    ],
  },
  HORZ_VANE: {
    label: "Horizontal Vane",
    value: "p",
    tagId: "horizontalVane",
    options: [
      {key: "left", value: "Left"},
      {key: "center", value: "Center"},
      {key: "right", value: "Right"},
      {key: "sides", value: "Sides"},
      {key: "auto", value: "Auto"},
      {key: "off", value: "OFF"},
      {key: "leftcenter", value:  "Left Center" },
      {key: "rightcenter", value:  "Right Center" },
    ],
  },
  LONG: {
    label: "Long",
    value: "q",
    tagId: "long"
  },
  ECO: {
    label: "Eco",
    value: "r",
    tagId: "eco"
  },
  TURBO: {
    label: "Turbo",
    value: "t",
    tagId: "turbo"
  },
  DISPLAY: {
    label: "Display",
    value: "l",
    tagId: "display"
  },
};

const getCommandType = (tagId) => {
  for (const item in CommandType) {
    const cmdType = CommandType[item];
    if (cmdType.tagId === tagId) {
      return cmdType;
    }
  }
  return null;
};

/********
 * Mode *
 ********/
const ModeOptions = [
  {key: "cool", value: "Cool"},
  {key: "heat", value: "Heat"},
  {key: "fan", value: "Fan"},
  {key: "dry", value: "Dry"},
  {key: "auto", value: "Auto"},
];

/********
 * FAN *
 ********/
const FanOptions = [
  {key: "low", value: "Low"},
  {key: "mid", value: "Mid"},
  {key: "high", value: "High"},
  {key: "auto", value: "Auto"},
];

/*****************
 * Vertical Vane *
 *****************/
const getVerticalVaneText = v => {
  const value = v.toLowerCase();
  switch (value) {
  case "top": return value;
  case "topcenter": return "Top Center";
  case "center": return value;
  case "bot": return "Bottom";
  case "botcenter": return "Bottom Center";
  case "auto": return value;
  case "off": return value;
  default: return "off";
  }
};

const VerticalVaneOptions = [
  { key: "top", value: "Top"},
  { key: "topcenter", value: "Top Center"},
  { key: "center", value: "Center"},
  { key: "bot", value: "Bottom"},
  { key: "botcenter", value: "Bottom Center"},
  { key: "auto", value: "Auto"},
  { key: "off",  value: "OFF"},
];

/*******************
 * Horizontal Vane *
 *******************/
const getHorizontalVaneText = v => {
  const value = v.toLowerCase();
  switch (value) {
  case "left": return value;
  case "center": return value;
  case "right": return value;
  case "sides": return value;
  case "auto": return value;
  case "off": return value;
  case "leftcenter": return "Left Center";
  case "rightcenter": return "Right Center";
  default: return "off";
  }
};

const HorizontalVaneOptions = [
  {key: "left", value: "Left"},
  {key: "center", value: "Center"},
  {key: "right", value: "Right"},
  {key: "sides", value: "Sides"},
  {key: "auto", value: "Auto"},
  {key: "off", value: "OFF"},
  {key: "leftcenter", value:  "Left Center" },
  {key: "rightcenter", value:  "Right Center" },
];

export  {
  CommandType,
  getCommandType,
  ModeOptions,
  FanOptions,
  getVerticalVaneText,
  VerticalVaneOptions,
  getHorizontalVaneText,
  HorizontalVaneOptions,
};
