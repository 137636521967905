import axios from 'axios';
import auth from '../auth';
import { makeTypes, makeActions as mac } from '../utils/reducers';

const t = makeTypes('login');

//TYPES
const SUBMIT = t('submit', true); // this create a login/submit constant type

//ACTIONS
export const submitStart = mac(SUBMIT.START); //NEVER USER 'type' AS PARAM
export const submitSuccess = mac(SUBMIT.SUCCESS, 'payload'); //NEVER USER 'type' AS PARAM
export const submitError = mac(SUBMIT.ERROR, 'error'); //NEVER USER 'type' AS PARAM
export const submitLogout = mac(SUBMIT.LOGOUT); //NEVER USER 'type' AS PARAM

//STATE INITIAL
const initState = {
  fetching: false,
  error: null, // msg
  loggedIn: false,
  didLogOut: false
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SUBMIT.START:
      console.log('start')
      return {
        ...state,
        fetching: true,
        error: null,
        loggedIn: false,
        didLogOut: false,
      };
    case SUBMIT.SUCCESS:
      console.log('success')
      return {
        ...state,
        error: null,
        loggedIn: true,
        fetching: false,
        didLogOut: false,
      };
    case SUBMIT.ERROR:
      console.log('error')
      return {
        ...state,
        error: action.error,
        loggedIn: false,
        fetching: false,
        didLogOut: false,
      };
    case SUBMIT.LOGOUT:
      console.log('logout')
      return {
        ...state,
        error: null,
        loggedIn: false,
        fetching: false,
        didLogOut: true,
    };

  default:
    return state;
  }
}

//TUNKS
export const login = (username, password) =>
  async (dispatch, getState) => {
    //TODO
    console.log('login')
    dispatch(submitStart())
    try {
      if (username == null || username.length === 0 || password == null || password.length === 0) {
        dispatch(submitError('EWRONGCREDENTIALS'))
        return;
      }
      let path = `https://cuby.cloud/api/v2/token/${username}`;
      let res = await axios.post(path, {
        password: password,
        expiration: 60 * 60 * 12
      })

    if (res.data != null && res.data.token != null) {
      const token = res.data.token;
      auth.authenticateUser(token);
      dispatch(submitSuccess('jwt'))
    } else {
      dispatch(submitError('EGENERIC'))
    }
    } catch (e) {
      console.log(JSON.stringify(e));
      if (e.message != null && e.message.includes('401')) {
        dispatch(submitError('EWRONGCREDENTIALS'))
      } else {
        dispatch(submitError('EGENERIC'))
      }
    }

  };


export const logout = () =>
  async (dispatch, getState) => {
    //TODO
    console.log('logout')
    try {

    auth.deauthenticateUser();
    dispatch(submitLogout())
    } catch (e) {
      console.log(e)
    }

  };