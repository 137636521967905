import axios from 'axios';
import auth from '../auth';
import { makeTypes, makeActions as mac } from '../utils/reducers';
import err from '../utils/errors';
import { post } from '../services/cubyAPI';
import { CommandType } from '../utils/cubyControl';

const t = makeTypes('control');

//TYPES
const FETCH_STATE = t('fetchState', true);
const SEND_COMMAND = t('sendCommand', true);

//ACTIONS
const fetchStateStart = mac(FETCH_STATE.START, 'cubyId');
const fetchStateSuccess = mac(FETCH_STATE.SUCCESS, 'payload');
const fetchStateError = mac(FETCH_STATE.ERROR, 'error');

const sendCommandStart = mac(SEND_COMMAND.START);
const sendCommandSuccess = mac(SEND_COMMAND.SUCCESS, 'newState');
const sendCommandError = mac(SEND_COMMAND.ERROR, 'error');


//STATE INITIAL
const initState = {
  sendCommand: {
    loading: false,
    error: null,
  },
  cubyId: "",
  loading: false,
  error: null,
  lastRequest: 0,
  state: {
    type: "",
    power: "",
    mode: "",
    fan: "",
    temperature: 0,
    verticalVane: "",
    horizontalVane: "",
    display: "",
    turbo: "",
    long: "",
    eco: ""
  }
};

export default function reducer(state = initState, action) {
  switch (action.type) {
  case FETCH_STATE.START:
    return {
      ...state,
      loading: true,
      cubyId: action.cubyId,
      error: null,
    };
  case FETCH_STATE.SUCCESS:
    return {
      ...state,
      loading: false,
      error: null,
      lastRequest: Date.now(),
      state: {
        ...action.payload
      }
    };
  case FETCH_STATE.ERROR:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case SEND_COMMAND.START:
    return {
      ...state,
      sendCommand: {
        ...state.sendCommand,
        loading: true,
        error: null,
      }
    };
  case SEND_COMMAND.SUCCESS:
    return {
      ...state,
      sendCommand: {
        ...state.sendCommand,
        loading: false,
        error: null,
      },
      state: {
        ...state.state,
        ...action.newState,
      }
    };
  case SEND_COMMAND.ERROR:
    return {
      ...state,
      sendCommand: {
        ...state.sendCommand,
        loading: false,
        error: null,
      }
    };
  default:
    return state;
  }
}

//TUNKS
export const getCubyState = (cubyId) =>
  async (dispatch, getState) => {
    const state = getState();
    const { Control: { cubyId: id, lastRequest }} = state;
    const delta = Date.now() - lastRequest;
    const needRequest = (cubyId !== id) || delta > 60;

    if (!needRequest) {
      return;
    }

    dispatch(fetchStateStart());
    try {
      if (auth.isUserAuthenticated() === false) {
        dispatch(fetchStateError(err.sessionExpired));
      }
      const token = auth.getToken();
      let path = `https://cuby.cloud/api/v2/state/${cubyId}?token=${token}`;
      let res = await axios.get(path);
      const payload = res.data;
      if (payload != null) {
        dispatch(fetchStateSuccess(payload));
      }
      else {
        dispatch(fetchStateError(err.internal));
      }
    }
    catch(e) {
      console.error(`[reducers/control] getCubyState:: `, e);
      if (e.message != null && e.message.includes('401')) {
        dispatch(fetchStateError(err.sessionExpired));
      } else {
        dispatch(fetchStateError(err.internal));
      }
    }
  };


export const sendCommand = (cubyId,
                            type,
                            newValue) => async (dispatch, getState) => {

  try {
    if (auth.isUserAuthenticated() === false) {
      dispatch(sendCommandError(err.sessionExpired));
      return;
    }

    const { Control: { state: { power }}} = getState();
    let typeValue = type.value;
    const body = {
      type: typeValue,
      [type.tagId]: newValue,
    };

    const isOnType = CommandType.IS_ON;
    const isOnValue = isOnType.value;
    if (type.value !== isOnValue) {
      if (power === "off") {
        body[isOnType.tagId] = "on";
        body.type = isOnValue;
      }
    }

    const token = auth.getToken();
    dispatch(sendCommandStart());
    await post(`state/${cubyId}`, token, body);
    dispatch(sendCommandSuccess(body));
  }
  catch(e) {
    console.error(`[reducers/control] sendCommand:: `, e);
    if (e.message != null && e.message.includes('401')) {
      dispatch(sendCommandError(err.sessionExpired));
    } else {
      dispatch(sendCommandError(err.internal));
    }
  }
};

export const increaseTemp = (cubyId) => async (dispatch, getState) => {
  const state = getState();
  let { Control: { state: { temperature } } } = state;
  if (temperature >= 31) {
    return;
  }
  temperature ++;
  await dispatch(sendCommand(cubyId, CommandType.TEMP, temperature));
};

export const decreaseTemp = (cubyId) => async (dispatch, getState) => {
  const state = getState();
  let { Control: { state: { temperature } } } = state;
  if (temperature <= 16) {
    return;
  }
  temperature --;
  await dispatch(sendCommand(cubyId, CommandType.TEMP, temperature));
};
