import axios from 'axios';
import auth from '../auth';
import { makeTypes, makeActions as mac } from '../utils/reducers';
import err from '../utils/errors';

const t = makeTypes('cuby');

//TYPES
const SUBMIT = t('submit', true);
const UPDATE_TAB = t('updateTab', false);
const UPDATE_INTERVAL = t('updateInterval', false);

//ACTIONS
export const submitStart = mac(SUBMIT.START, 'cuby'); //NEVER USER 'type' AS PARAM
export const submitSuccess = mac(SUBMIT.SUCCESS, 'payload'); //NEVER USER 'type' AS PARAM
export const submitError = mac(SUBMIT.ERROR, 'error'); //NEVER USER 'type' AS PARAM
export const updateTab = mac(UPDATE_TAB, 'payload');
export const updateInterval = mac(UPDATE_INTERVAL, 'interval');

//STATE INITIAL
const initState = {
  fetching: false,
  error: null, // msg
  hasCubyData: false,
  cubyData: null,
  currentCubyId: null,
  tabSelected: "statistics",
  currentCuby: null,
  dataInterval: 24
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SUBMIT.START:
      return {
        ...state,
        fetching: true,
        error: null,
        currentCubyId: action.cuby.id,
        currentCuby: action.cuby
      };
    case SUBMIT.SUCCESS:
      return {
        ...state,
        error: null,
        hasCubyData: true,
        fetching: false,
        cubyData: action.payload,
      };
    case SUBMIT.ERROR:
      return {
        ...state,
        error: action.error,
        hasCubyData: false,
        fetching: false,
      };
  case UPDATE_TAB:
    return {
      ...state,
      tabSelected: action.payload
    };
    case UPDATE_INTERVAL:
      return {
        ...state,
        dataInterval: action.interval,
      };
  default:
    return state;
  }
}

//TUNKS
export const getCubyData = (cuby) =>
  async (dispatch, getState) => {
    dispatch(submitStart(cuby));
    try {
        if (auth.isUserAuthenticated() === false) {
            dispatch(submitError(err.sessionExpired))
        }
        const token = auth.getToken();
        const state = getState();
        const { Cuby: {dataInterval}} = state;

        let path = `https://cuby.cloud/api/v2/history/of/${cuby.id}/last/${dataInterval}/hours/type/json?token=${token}`;
        let res = await axios.get(path)
        let pathDelta = `https://cuby.cloud/api/v2/history/delta/of/${cuby.id}?token=${token}`;
        let resDelta = await axios.get(pathDelta)
        res.data.weekDelta = resDelta.data;

      console.log(res)

      if (res.data != null) {
      dispatch(submitSuccess(res.data));
    } else {
      dispatch(submitError(err.internal));
    }
    } catch (e) {
      if (e.message != null && e.message.includes('401')) {
        dispatch(submitError(err.sessionExpired));
      } else {
        dispatch(submitError(err.internal));
      }
    }
  };
