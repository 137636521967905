import jwt from 'jsonwebtoken';
import moment from 'moment';

class Auth {
  static getToken() {
    return localStorage.getItem('token');
  }

  static deauthenticateUser() {
    localStorage.removeItem('token');
  }

  static isTokenValid(token) {
    var decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken == null) return false;
    if (decodedToken.payload == null) return false;

    if (decodedToken.payload.exp < moment().unix()) {
      return false;
    }

    return true;
  }

  static isUserAuthenticated() {
    let token = localStorage.getItem('token');

    if (token == null) return false;

    return this.isTokenValid(token);
  }

  static authenticateUser(token) {
    localStorage.setItem('token', token);
  }

}

export default Auth;
