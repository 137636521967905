import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilWalk,
  cisWalk,
  cisDollar,
  cisPeople,
  cidDoor,
  cilCallToAction,
  cilDoor,
  cisDoor,
  cilBraille,
  cilSun,
  cilEnergyCircle,
  cilScreenSmartphone,
  cilUser,
  cilTask,
  cilSettings,
  cilBell,
  cilEnvelopeOpen,
  cilCommentSquare,
  cilCreditCard,
  cilLockLocked,
  cilFile,
  cilMoon,
  cilVector,
  cilSpeech,
  cilLanguage,
  cilAlarm,
  cilRunning,
  cilWindow,
  cilPlus,
  cilMinus,
  cilList
} from '@coreui/icons-pro';

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilWalk,
  cisWalk,
  cilBraille,
  cilCallToAction,
  cisDollar,
  cilEnergyCircle,
  cilDoor,
  cisDoor,
  cidDoor,
  cisPeople,
  cilSun,
  cilScreenSmartphone,
  cilMoon,
  cilTask,
  cilUser,
  cilSettings,
  cilBell,
  cilEnvelopeOpen,
  cilCommentSquare,
  cilCreditCard,
  cilLockLocked,
  cilFile,
  cilVector,
  cilSpeech,
  cilLanguage,
  cilAlarm,
  cilRunning,
  cilWindow,
  cilPlus,
  cilMinus,
  cilList
});
